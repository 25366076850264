/* ==========================================================================
Socials count
========================================================================== */
.socials--count {
    @extend
    .pt-2,
    .pb-6,
    .py-lg-0;

    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }

  .socials--count-title {
    @extend .mb-1;

    color: $gray-custom-2;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }

  .socials--count-item {
    @extend
      .mb-2,
      .mb-lg-3;

    position: relative;
  }

  .socials--count-number {
    @extend .h2;

    margin-bottom: 0 !important;

    @include media-breakpoint-down(md) {
      font-size: 60px;
    }
  }

  .social--count-gain {
    position: absolute;
    bottom: 0;
    width: 100px;
    text-align: left;

    path {
      fill: $success;
    }

    @include media-breakpoint-down(md) {
      left: 130px;
    }

    @include media-breakpoint-up(lg) {
      right: -110px;
    }
  }
