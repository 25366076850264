/* ==========================================================================
Slider half variables
========================================================================== */
$slider-half-height: 434px;
$slider-half-image-height: 412px;

/* ==========================================================================
Slider half
========================================================================== */
.slider--half-wrapper {
  @extend .d-inline-block;

  position: relative;
  width: 100%;
  height: $slider-half-height;

  @include media-breakpoint-up(md) {
    height: 100vh;
    width: 45%;
    margin: 0;
  }
}

.slider--half {
  height: $slider-half-image-height;

  @include media-breakpoint-up(md) {
    height: 100vh;
  }
}

.slider--half-item {
  height: 100%;
  display: inline-block;
  background-image: url("/assets/img/bg-slide.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .lazyload-wrapper {
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    height: 100%;
  }
}
