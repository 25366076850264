/* ==========================================================================
Pagination
========================================================================== */
.pagination {
  @extend .py-4;

  .col {
    display: flex;
    justify-content: center;
  }
}

.pagination {
  color: $white;

  li {
    width: 45px;
    height: 45px;
    border: 0;
    text-align: center;
    line-height: 40px;
    margin-left: 5px;
    margin-right: 5px;
    font-family: $font-family-2;
    display: inline-block;

    a {
      color: $gray-custom-2;
      width: 100%;
      display: inline-block;

      @include transition();
    }

    &:hover,
    &:focus {
      a {
        border: 0 !important;
        outline: 0 !important;
        color: $white !important;
        text-decoration: none;
      }
    }
  }

  .active {
    color: $white;
  }

  .fade {
    opacity: 0.6;
  }

  .prev,
  .next {
    border: 0;

    &:hover,
    &:focus {
      a {
        background-color: transparent;
        color: white !important;
      }
    }
  }
}
