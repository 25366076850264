/* ==========================================================================
Hamburger
========================================================================== */
.hamburger--desktop {
  @extend
    .d-none,
    .d-md-block;
}

.hamburger--mobile {
  @extend .d-md-none;
}

.hamburger {
  background-color: $black !important;
  border-color: $black !important;
  font-family: $font-family-2;
  font-weight: 500;
  height: 50px;
  padding: 0;
  position: absolute;
  top: 0;
  right: 10px;
  text-transform: uppercase;
  width: 50px;
  z-index: 530;

  @include font-size(21);

  @include media-breakpoint-up(md) {
    top: 10px;
  }

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    border-color: $black !important;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    transition: .25s ease-in-out;
  }

  @include media-breakpoint-up(md) {
    &::before {
      background-color: $white;
      top: auto;
      bottom: 9px;
      height: 3px;
      left: 20px;
      right: 8px;
    }
  }

  &.btn {
    @extend .mt-0;
  }
}

.hamburger-box {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
    height: auto;
  }
}

.hamburger-inner {
  width: 37px;
  height: 4px;
  position: absolute;
  top: 20px;
  left: 5px;
  background-color: $white;

  &::before {
    background-color: $white;
    bottom: -9px;
    content: "";
    height: 4px;
    left: 5px;
    position: absolute;
    width: 27px;
    transition: .25s ease-in-out;
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &:after {
    transition: .25s ease-in-out;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
  }

  @include media-breakpoint-up(md) {
    position: relative;
    top: -4px;
    left: 0;
    width: auto;
    height: auto;
    background-color: transparent;

    &::before {
      bottom: -2px;
      right: 10px;
      left: 0;
      width: 100%;
      height: 3px;
    }

    &::after {
      content: "MENU";
      bottom: 0 !important;
      font-size: 21px;
    }
  }
}

.hamburger-close {
  &.hamburger {
    &::before {
      background-color: $white;
      content: "";
      display: block;
      height: 4px;
      left: 10px;
      top: 43%;
      width: 35px;
      transform: rotate(-45deg);
      transition: .25s ease-in-out;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.1s ease-in-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);

      @include media-breakpoint-up(md) {
        top: 47%;
        width: 30px;
        transition: bottom 0.1s ease-in-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }

  &.is-active {
    .hamburger-inner {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;

      @supports (-ms-ime-align:auto) {
        top: -3px;
      }

      &::before,
      &::after {
        width: 35px;
        height: 4px;
        background-color: $white;
        border-radius: 0;
        position: absolute;
        display: block;
      }

      &::before {
        content: "";
        left: 10px;
        bottom: 22px;
        transform: rotate(45deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &::after {
        opacity: 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hamburger-close {
    &.is-active {
      .hamburger-inner {
        &::before,
        &::after {
          width: 30px;
          left: -13px;
        }

        &::before {
          top: -7px !important;
          transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);

          @-moz-document url-prefix() {
            top: 9px !important;
          }
        }

        &::after {
          top: -7px !important;
          transition: bottom 0.1s ease-in-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}
