/* ==========================================================================
Team variables
========================================================================== */
$team--member-height: 485px;
$team--member-height-xs: 278px;

/* ==========================================================================
Team categories
========================================================================== */
.team--categories {
  @extend
    .text-md-center,
    .pl-0;

  @include media-breakpoint-down(sm) {
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 10px;
  }
}

.section--team {
  ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
    background-color: #707070;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: white !important;
  }
}


.team--category {
  @extend
    .mb-1,
    .mb-md-2;

  display: inline-block;
  list-style-type: none;
  margin-left: 15px;
  margin-right: 25px;

  &:first-of-type {
    margin-left: 0 !important;
  }

  &:last-of-type {
    margin-right: 0 !important;
  }

  @include media-breakpoint-up(md) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

/* ==========================================================================
Team btn
========================================================================== */
.team--category-btn {
  @extend .d-inline-block;

  color: $white;
  font-weight: bold;
  overflow: hidden;
  position: relative;
  min-width: 200px;
  text-transform: uppercase;
  font-family: $font-family-2;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  border: 2px solid;
  padding: $btn-padding-y $btn-padding-x;

  @include transition();

  &:hover,
  &:focus {
    text-decoration: none;
    outline: 0;
  }
}

.team--category-active {
  .team--category-btn {
    position: relative;

    &::after {
      display: block;
      bottom: 0;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
    }
  }
}

/* ==========================================================================
Team members
========================================================================== */
.team--member {
  @extend
  .d-inline-block,
  .mb-1;

  width: 50%;
  height: $team--member-height-xs;
  padding-left: 5px;
  padding-right: 5px;

  @include media-breakpoint-up(md) {
    height: $team--member-height;
  }

  @include media-breakpoint-up(lg) {
    width: 20%;
    height: $team--member-height;
  }
}

.team--member-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.team--member-image {
  position: relative;
  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.team--member-content {
  @extend .pl-2;

  position: absolute;
  width: 100%;
  bottom: 0;
}

.team--member-title {
  p {
    @extend
      .h4,
      .mb-0;

    line-height: 20px;
    text-transform: none;

    @include media-breakpoint-up(md) {
      line-height: 30px;
    }
  }
}

.team--member-subtitle {
  @include font-size(12);

  @include media-breakpoint-up(md) {
    @include font-size(18);

    font-weight: bold;
  }
}
