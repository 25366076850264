/* ==========================================================================
Fonts
========================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

/* ==========================================================================
Druk Web
========================================================================== */
@font-face {
  font-family: 'Druk Web';
  src: url('/assets/fonts/Druk-Heavy.otf');
  src: url('/assets/fonts/Druk-Medium.otf'),
       url('/assets/fonts/Druk-Super.otf');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}
