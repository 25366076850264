/* ==========================================================================
Buttons
========================================================================== */
.btn {
  @extend .mt-1;
  @extend .mt-md-3;

  text-transform: uppercase;
  font-family: $font-family-2;
}

.btn-white {
  &:hover,
  &:focus {
    color: $white;
    background-color: $black;
  }
}

.btn-black {
  border-color: $black;

  &:hover,
  &:focus {
    color: $black;
    background-color: $white;
  }
}

.btn-white-filled {
  background-color: $white;
  color: $black !important;

  &:hover {
    color: $white !important;
    background-color: $black;
    border: 2px solid $white !important;
  }
}

.btn-outline-light {
  color: $white !important;
  background-color: $black !important;
  border: 2px solid $white !important;

  &:hover {
    color: $black !important;
    background-color: $white !important;
  }
}

.btn-outline-dark {
  &:hover {
    color: $white !important;
    background-color: $black !important;
  }
}

.btn-dark {
  &:hover,
  &:focus {
    box-shadow: none !important;
    border: 0 !important;
    outline: 0 !important;
  }
}

/* ==========================================================================
Custom Block Buttons
========================================================================== */
.block--buttons {
  font-size: 0;

  .btn + .btn {
    @extend .ml-0;
    @extend .ml-sm-2;
    @extend .mt-2;
    @extend .mt-sm-0;
  }
}

.btn-min-width {
  min-width: 200px;
}

@include media-breakpoint-down(xs) {
  .block--buttons {
    .btn {
      width: 100%;
    }
  }
}
