/* ==========================================================================
Swiper variables
========================================================================== */
$swiper-btn-height: 45px;
$swiper-btn-width: 45px;

/* ==========================================================================
Swiper
========================================================================== */
.swiper-slide {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.swiper-wrapper {
  height: 100%;
}

.swiper-container--half {
  margin-top: 60px;
  margin-left: 15px;
  margin-right: 15px;
  height: 100%;

  @include media-breakpoint-up(md) {
    margin: 0;
  }
}

/* ==========================================================================
Swiper nav
========================================================================== */
.swiper-nav {
  @extend
    .d-none,
    .d-md-flex,
    .px-3;

  position: absolute;
  top: 50%;
  justify-content: space-between;
  width: 100%;
  z-index: 3;

  .swiper-button-next,
  .swiper-button-prev {
    @extend .p-0;

    @include transition();

    background-color: transparent;
    border: 3px solid white;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: $swiper-btn-height;
    width: $swiper-btn-width;

    &::after {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
    }

    &:focus,
    &:hover {
      background-color: transparent;
      outline: 0;
    }
  }

  .swiper-button-prev {
    &::after {
      content: url('/../../assets/arrow-left.svg');
    }
  }

  .swiper-button-next {
    &::after {
      content: url('/../../assets/arrow-right.svg');
    }
  }
}

/* ==========================================================================
Swiper pagination
========================================================================== */
.swiper-pagination {
  @extend .w-100;

  position: absolute;
  bottom: 10px;
  text-align: center;
  z-index: 2;

  @include media-breakpoint-up(md) {
    bottom: 20px;
  }

  .swiper-pagination-bullet {
    @extend .p-0;

    @include transition();

    background-color: $white;
    border: 0 !important;
    display: inline-block;
    height: 2px;
    margin-left: 5px;
    opacity: 0.7;
    width: 30px;
  }
}

.swiper-pagination-bullet-active {
  width: 50px !important;
  opacity: 1 !important;
}
