/* stylelint-disable */

/* ==========================================================================
Calculate REM and fallback
========================================================================== */
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size !important;
  font-size: calculateRem($size) !important;
}

@mixin letter-spacing($letter-spacing) {
  letter-spacing: ($letter-spacing / 1000) * 1em;
}

/* stylelint-enable */
