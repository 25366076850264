/* ==========================================================================
Cookiebot
========================================================================== */
html {
  #CybotCookiebotDialog {
    font-family: $font-family-1 !important;
    width: calc(100% - 30px);
  }

  #CybotCookiebotDialogBodyContentTitle {
    font-family: $font-family-2 !important;
    font-size: 28px;
  }

  #CybotCookiebotDialogHeader,
  #CybotCookiebotDialogPoweredByText,
  #CybotCookiebotDialogPoweredbyCybot {
    @extend .d-none;
  }

  #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    color: $white !important;

    &::after {
      color: $white !important;
    }
  }

  #CybotCookiebotDialogFooter {
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
      @extend .btn, .btn-sm, .btn-red, .w-auto;

      font-size: 18px;
    }

    #CybotCookiebotDialogBodyButtonDecline,
    #CybotCookiebotDialogBodyLevelButtonCustomize,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
      @extend .btn, .btn-sm, .btn-outline-white, .w-auto;

      font-size: 18px;

      &.CybotCookiebotDialogHide {
        @extend .d-none;
      }

      .CybotCookiebotDialogArrow {
        @extend .d-none;
      }
    }
  }

  #CybotCookiebotDialogTabContent
    input:checked
    + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $red;
  }

  #CybotCookiebotDialogNav
    .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-color: $red;
    color: $red;
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    color: $red;
  }
}
