/* ==========================================================================
ImageFull
========================================================================== */
.image-full--wrapper {
  max-height: 100vh;

  @include media-breakpoint-up(lg) {
    height: 100vh;
  }
}

.image-full--frame img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-full--frame{
  height: 100%;
  width: 100%;
}

.img-fill {
  width: -webkit-fill-available;
  max-width: 200px;
}
