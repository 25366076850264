/* ==========================================================================
Social media
========================================================================== */
.social--media {
    @extend .mt-2;

    padding-left: 0;
  }

  .social--media-item {
    @extend .pr-2;

    display: inline-block;
    list-style-type: none;
  }

  .social--media-title {
    @extend
      .pt-5;

    font-size: 35px;
  }

  .social--media-link {
    @extend .d-inline-block;

    font-weight: bold;
    line-height: 1.875rem;
    overflow: hidden;
    position: relative;

    &::after {
      @extend
        .d-none,
        .d-lg-block;

      @include transition();

      bottom: 0;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
      transform: translateX(-102%);
    }

    &:focus,
    &:hover {
      text-decoration: none;

      &::after {
        transform: translateX(0);
      }
    }
  }