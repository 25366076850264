/* ==========================================================================
Footer
========================================================================== */
.footer {
  @extend
  .d-flex,
  .py-md-8,
  .pb-8;

  flex-wrap: wrap;
  justify-content: center;
  line-height: 2;

  @include font-size(15);
}

/* ==========================================================================
Footer list
========================================================================== */
.footer--list {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

.footer--item {
  @extend .px-1;

  display: inline-block;
  list-style-type: none;
  text-align: center;
}

/* ==========================================================================
Footer link
========================================================================== */
.footer--link {
  @extend .d-inline-block;

  font-weight: bold;
  line-height: 1.875rem;
  overflow: hidden;
  position: relative;
  text-decoration: underline;
}

/* ==========================================================================
Footer bottom
========================================================================== */
.footer--bottom {
  font-weight: bold;
  opacity: 50%;
  display: block;
}

/* ==========================================================================
Footer color
========================================================================== */
.footer--color-dark {
  @extend
    .bg-black,
    .text-white;

  .footer--link {
    color: $white;
  }

  .social--media-link {
    color: white;
  }
}

.footer--color-light {
  @extend
    .bg-white,
    .text-black;

  .footer--link {
    color: $black;
  }
}
