/* ==========================================================================
Typography
========================================================================== */
h1,
.h1 {
  @extend .mb-0;

  @include font-size(55);

  font-family: $font-family-2;
  font-weight: 800;
  text-transform: uppercase;
  line-height: .8;

  @include media-breakpoint-up(sm) {
    @include font-size(92);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(100);
  }

  @include media-breakpoint-up(xxl) {
    @include font-size(150);
  }
}

h2,
.h2 {
  @extend
    .mb-2,
    .mb-md-4;

  @include font-size(42);

  font-family: $font-family-2;
  font-weight: 800;
  text-transform: uppercase;
  line-height: .8;

  @include media-breakpoint-up(md) {
    @include font-size(42);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(65);
  }
}

h3,
.h3 {
  @extend .mb-0;

  @include font-size(22);

  font-family: $font-family-2;
  font-weight: 800;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    @include font-size(28);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(40);
  }
}

h4,
.h4 {
  @extend .mb-0;

  @include font-size(25);

  font-family: $font-family-2;
  font-weight: 800;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    @include font-size(28);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(35);
  }
}

h5,
.h5 {
  @extend .mb-0;

  @include font-size(20);

  font-family: $font-family-2;
  font-weight: 800;

  @include media-breakpoint-up(md) {
    @include font-size(24);
  }
}

h6,
.h6 {
  @extend
    .mb-2,
    .mb-md-3;

  @include font-size(16);

  font-family: $font-family-1;
  font-weight: 800;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    @include font-size(22);
  }
}

p, li {
  font-weight: 300;

  @include font-size(18);
}

p a {
  text-decoration: underline;
}

small {
  @include font-size(12);
}

.font-weight-light {
  font-family: $font-family-1;
  font-weight: 300;
}

.subtitle {
  opacity: 0.5;
}

.error {
  color: $red;
  margin-top: -5px;
}
