/* ==========================================================================
Companies
========================================================================== */
.companies--title {
    @extend
      .h4,
      .pb-6,
      .pb-md-10,
      .pt-10,
      .pt-md-0;

    text-align: center;
  }

  .companies--list {
    display: flex;
    flex-wrap: wrap;
  }

  .companies--list-animated {
    overflow: hidden;
    transition: height 500ms ease
  }

  .companies--box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .companies--item {
    @extend
      .mb-4,
      .px-2;

    display: inline-block;
    width: 50%;
    height: 90px;

    @include media-breakpoint-up(md) {
      height: 130px;
    }

    @include media-breakpoint-up(lg) {
      width: 20%;
    }
  }

  .companies--image {
    max-height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }

  .companies--btn {
    @extend
      .h4,
      .mt-6,
      .mt-md-10,
      .mb-10,
      .mb-md-0;

    color: $white;
    border: none;
    padding: 0 !important;
    margin: 0 auto;
    text-align: center;
    display: block;
    cursor: pointer;
    background: none;

    &:hover, 
    &:focus,
    &:active
     {
      text-decoration: none;
      outline: none;
    }
  }
