/* ==========================================================================
Animate.css - Custom animations
========================================================================== */
  .delay-02 {
    animation-delay: 0.2s;
  }

  .delay-04 {
    animation-delay: 0.4s;
  }

  .delay-06 {
    animation-delay: 0.6s;
  }

  .delay-08 {
    animation-delay: 0.8s;
  }

  .animate--faster {
    animation-duration: 0.6s;
  }

/* ==========================================================================
fadeInUpSmall
========================================================================== */
  @-webkit-keyframes fadeInUpSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 25%, 0);
      transform: translate3d(0, 25%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInUpSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 25%, 0);
      transform: translate3d(0, 25%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .animate__fadeInUpSmall {
    -webkit-animation-name: fadeInUpSmall;
    animation-name: fadeInUpSmall;
  }

/* ==========================================================================
slideInMobile (Mobile Menu)
========================================================================== */
  @-webkit-keyframes slideInMobile {
    from {
      width: 0;
      left: 50%; /*Add this*/
      transform: translate(-50%, 0); /*Add this*/
    }

    to {
      width: 100%;
      left: 50%; /*Add this*/
      transform: translate(-50%, 0); /*Add this*/
    }
  }
  @keyframes slideInMobile {
    from {
      width: 0;
      left: 50%; /*Add this*/
      transform: translate(-50%, 0); /*Add this*/
    }

    to {
      width: 100%;
      left: 50%; /*Add this*/
      transform: translate(-50%, 0); /*Add this*/
    }
  }

  .animate__slideInMobile {
    -webkit-animation-name: slideInMobile;
    animation-name: slideInMobile;
    animation-duration: 0.3s !important;
  }

/* ==========================================================================
slideOutMobile (Mobile Menu)
========================================================================== */
  @-webkit-keyframes slideOutMobile {
    from {
      width: 100%;
      left: 50%; /*Add this*/
      transform: translate(-50%, 0); /*Add this*/
    }

    to {
      width: 0;
      left: 50%; /*Add this*/
      transform: translate(-50%, 0); /*Add this*/
    }
  }
  @keyframes slideOutMobile {
    from {
      width: 100%;
      left: 50%; /*Add this*/
      transform: translate(-50%, 0); /*Add this*/
    }

    to {
      width: 0;
      left: 50%; /*Add this*/
      transform: translate(-50%, 0); /*Add this*/
    }
  }

  .animate__slideOutMobile {
    -webkit-animation-name: slideOutMobile;
    animation-name: slideOutMobile;
    animation-duration: 0.3s !important;
  }

/* ==========================================================================
slideInUpMobile (Mobile Menu Nav Items)
========================================================================== */
  @-webkit-keyframes slideInUpMobile {
    0%   {transform: translateY(60px);}
    90%  {transform: translateY(-10px);}
    100% {transform: translateY(0);}
  }
  @keyframes slideInUpMobile {
    0%   {transform: translateY(60px);}
    90%  {transform: translateY(-5px);}
    100% {transform: translateY(0);}
  }

  .animate__slideInUpMobile {
    -webkit-animation-name: slideInUpMobile;
    animation-name: slideInUpMobile;
    animation-delay: 0.2s;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }

/* ==========================================================================
kenBurns
========================================================================== */
  @-webkit-keyframes kenBurns {
    from {
      -webkit-transform: scale(1.08) rotate(-.02deg);
      transform: scale(1.08) rotate(-.02deg);
      opacity: .2;
    }

    to {
      opacity: 1;
      -webkit-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
    }
  }
  @keyframes kenBurns {
    from {
      -webkit-transform: scale(1.08) rotate(-.02deg);
      transform: scale(1.08) rotate(-.02deg);
      opacity: .2;
    }

    to {
      opacity: 1;
      -webkit-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
    }
  }

  .animate__kenBurns {
    -webkit-animation-name: kenBurns;
    animation-name: kenBurns;
    transition: transform 5s cubic-bezier(.3,0,.7,1), opacity 1s ease;
  }

/* ==========================================================================
fadeInDownSmall
========================================================================== */
  @-webkit-keyframes fadeInDownSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -25%, 0);
      transform: translate3d(0, -25%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInDownSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -25%, 0);
      transform: translate3d(0, -25%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .animate__fadeInDownSmall {
    -webkit-animation-name: fadeInDownSmall;
    animation-name: fadeInDownSmall;
  }

/* ==========================================================================
fadeInRightSmall
========================================================================== */
  @-webkit-keyframes fadeInRightSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(20%, 0, 0);
      transform: translate3d(20%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInRightSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(20%, 0, 0);
      transform: translate3d(20%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .animate__fadeInRightSmall {
    -webkit-animation-name: fadeInRightSmall;
    animation-name: fadeInRightSmall;
  }

/* ==========================================================================
fadeInLeftSmall
========================================================================== */
  @-webkit-keyframes fadeInLeftSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-20%, 0, 0);
      transform: translate3d(-20%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInLeftSmall {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-20%, 0, 0);
      transform: translate3d(-20%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .animate__fadeInLeftSmall {
    -webkit-animation-name: fadeInLeftSmall;
    animation-name: fadeInLeftSmall;
  }

/* ==========================================================================
slideInLeftSmall
========================================================================== */
  @-webkit-keyframes slideInLeftSmall {
    from {
      -webkit-transform: translate3d(-20%, 0, 0);
      transform: translate3d(-20%, 0, 0);
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes slideInLeftSmall {
    from {
      -webkit-transform: translate3d(-20%, 0, 0);
      transform: translate3d(-20%, 0, 0);
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .animate__slideInLeftSmall {
    -webkit-animation-name: slideInLeftSmall;
    animation-name: slideInLeftSmall;
  }

/* ==========================================================================
different animations mobile
========================================================================== */
@include media-breakpoint-down(md) {
  .animate__slideInLeftSmall,
  .animate__fadeInLeftSmall,
  .animate__fadeInRightSmall,
  .animate__fadeInRight {
    animation-name: fadeInUpSmall;
    -webkit-animation-name: fadeInUpSmall;
  }
}