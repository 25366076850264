/* ==========================================================================
Variables
========================================================================== */
// Breakpoint MD
$blog-grid-column-gap-md: 20px;
$blog-grid-row-gap-md: 20px;
$blog-img-height-md-1: 195px;
$blog-img-height-md-2: calc((#{$blog-img-height-md-1} * 2) + #{$blog-grid-row-gap-md});
$blog-img-height-md-3: 255px;
$blog-img-height-md-4: calc((#{$blog-img-height-md-3} * 2) + #{$blog-grid-row-gap-md});
$blog-img-height-md-5: 395px;

// Breakpoint LG
$blog-grid-column-gap-lg: 20px;
$blog-grid-row-gap-lg: 20px;
$blog-img-height-lg-1: 195px;
$blog-img-height-lg-2: calc((#{$blog-img-height-lg-1} * 2) + #{$blog-grid-row-gap-lg});
$blog-img-height-lg-3: 255px;
$blog-img-height-lg-4: calc((#{$blog-img-height-lg-3} * 2) + #{$blog-grid-row-gap-lg});
$blog-img-height-lg-5: 495px;

// Breakpoint XL
$blog-grid-column-gap-xl: 50px;
$blog-grid-row-gap-xl: 50px;
$blog-img-height-xl-1: 295px;
$blog-img-height-xl-2: calc((#{$blog-img-height-xl-1} * 2) + #{$blog-grid-row-gap-xl});
$blog-img-height-xl-3: 355px;
$blog-img-height-xl-4: calc((#{$blog-img-height-xl-3} * 2) + #{$blog-grid-row-gap-xl});
$blog-img-height-xl-5: 595px;

/* ==========================================================================
Blog overview
========================================================================== */
@include media-breakpoint-up(md) {
  .blog--overview {
    display: grid;
    grid-column-gap: $blog-grid-column-gap-md;
    grid-row-gap: $blog-grid-row-gap-md;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, auto);
  }
}

@include media-breakpoint-up(lg) {
  .blog--overview {
    grid-column-gap: $blog-grid-column-gap-lg;
    grid-row-gap: $blog-grid-row-gap-lg;
  }
}

@include media-breakpoint-up(xl) {
  .blog--overview {
    grid-column-gap: $blog-grid-column-gap-xl;
    grid-row-gap: $blog-grid-row-gap-xl;
  }
}

/* ==========================================================================
Blog item
========================================================================== */
.blog--item {
  @extend
    .mb-3,
    .mb-md-0;

  overflow: hidden;
  height: $blog-img-height-xl-1;

  img {
    height: $blog-img-height-xl-1;
  }
}

@include media-breakpoint-up(md) {
  .blog--item-0 {
    grid-area: 1 / 1 / 2 / 7;
  }

  .blog--item-1 {
    grid-area: 2 / 1 / 3 / 3;
  }

  .blog--item-2 {
    grid-area: 2 / 3 / 3 / 5;
  }

  .blog--item-3 {
    grid-area: 2 / 5 / 3 / 7;
  }

  .blog--item-4 {
    grid-area: 3 / 1 / 4 / 3;
  }

  .blog--item-5 {
    grid-area: 4 / 1 / 5 / 3;
  }

  .blog--item-6 {
    grid-area: 3 / 3 / 5 / 7;
  }

  .blog--item-7 {
    grid-area: 5 / 1 / 6 / 5;
  }

  .blog--item-8 {
    grid-area: 6 / 1 / 7 / 5;
  }

  .blog--item-9 {
    grid-area: 5 / 5 / 7 / 7;
  }
}

@include media-breakpoint-up(md) {
  .blog--item-0 {
    height: $blog-img-height-md-5;

    img {
      height: $blog-img-height-md-5;
    }
  }

  .blog--item-1,
  .blog--item-2,
  .blog--item-3,
  .blog--item-4,
  .blog--item-5 {
    height: $blog-img-height-md-1;

    img {
      height: $blog-img-height-md-1;
    }
  }

  .blog--item-6 {
    height: $blog-img-height-md-2;

    img {
      height: $blog-img-height-md-2;
    }
  }

  .blog--item-7,
  .blog--item-8 {
    height: $blog-img-height-md-3;

    img {
      height: $blog-img-height-md-3;
    }
  }

  .blog--item-9 {
    height: $blog-img-height-md-4;

    img {
      height: $blog-img-height-md-4;
    }
  }
}

@include media-breakpoint-up(lg) {
  .blog--item-0 {
    height: $blog-img-height-lg-5;

    img {
      height: $blog-img-height-lg-5;
    }
  }

  .blog--item-1,
  .blog--item-2,
  .blog--item-3,
  .blog--item-4,
  .blog--item-5 {
    height: $blog-img-height-lg-1;

    img {
      height: $blog-img-height-lg-1;
    }
  }

  .blog--item-6 {
    height: $blog-img-height-lg-2;

    img {
      height: $blog-img-height-lg-2;
    }
  }

  .blog--item-7,
  .blog--item-8 {
    height: $blog-img-height-lg-3;

    img {
      height: $blog-img-height-lg-3;
    }
  }

  .blog--item-9 {
    height: $blog-img-height-lg-4;

    img {
      height: $blog-img-height-lg-4;
    }
  }
}

@include media-breakpoint-up(xl) {
  .blog--item-0 {
    height: $blog-img-height-xl-5;

    img {
      height: $blog-img-height-xl-5;
    }
  }

  .blog--item-1,
  .blog--item-2,
  .blog--item-3,
  .blog--item-4,
  .blog--item-5 {
    height: $blog-img-height-xl-1;

    img {
      height: $blog-img-height-xl-1;
    }
  }

  .blog--item-6 {
    height: $blog-img-height-xl-2;

    img {
      height: $blog-img-height-xl-2;
    }
  }

  .blog--item-7,
  .blog--item-8 {
    height: $blog-img-height-xl-3;

    img {
      height: $blog-img-height-xl-3;
    }
  }

  .blog--item-9 {
    height: $blog-img-height-xl-4;

    img {
      height: $blog-img-height-xl-4;
    }
  }
}

/* ==========================================================================
Blog link
========================================================================== */
.blog--link {
  @extend
    .d-block,
    .w-100;

  position: relative;

  &::before {
    @extend .w-100;

    background: linear-gradient(to bottom, rgba($black, 0) 50%, rgba($black, 1) 100%);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  img {
    object-fit: cover;
    transition: all 0.5s ease-in-out;
    width: 100%;
  }

  .blog--inner {
    @extend .px-1;

    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 3;
  }

  .blog--title {
    @extend
      .h3,
      .mb-0;

    color: $white;
    line-height: 0.9;
  }

  .blog--category {
    @extend .mb-0;

    color: $white;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &:focus,
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

/* ==========================================================================
Filter categories
========================================================================== */
.filter--categories {
  @extend .pl-0;

  @include media-breakpoint-down(sm) {
    .team--category {
      margin-left: 0;
    }
  }
}

/* ==========================================================================
Wrapper
========================================================================== */
.wrapper {
  background-color: $black;
}
