/* ==========================================================================
Section
========================================================================== */

/* ==========================================================================
Section full
========================================================================== */
.section--full {
  @extend .py-6, .py-md-0, .d-flex, .align-items-center;

  position: relative;
  height: auto;
  min-height: 600px;

  @include media-breakpoint-up(md) {
    min-height: 600px;
    height: 100vh;
    min-height: 1000px;
  }

  .brand-story{
    p{
      font-size: 2rem;
    }
  }
}

/* ==========================================================================
Section auto
========================================================================== */
.section--auto {
  h2 {
    @extend .pt-3;
  }
}

/* ==========================================================================
Section companies
========================================================================== */
.section--companies {
  @extend .d-flex, .align-items-center;

  position: relative;
  height: auto;
  min-height: 600px;

  @include media-breakpoint-up(md) {
    padding: 120px 0;
  }
}

/* ==========================================================================
Section cases
========================================================================== */
.section--cases-top {
  padding-top: 0 !important;
  align-items: flex-start !important;

  @include media-breakpoint-down(sm) {
    .section--right {
      margin-left: -60px;
      margin-right: -60px;
      width: 110%;

      .col--inner {
        margin-left: 25px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    align-items: center !important;
  }
}

/* ==========================================================================
Section contact
========================================================================== */
.section--contact {
  .section--half {
    @include media-breakpoint-up(md) {
      height: 100vh;
      overflow: scroll;
    }
  }

  .section--half-inner {
    @media screen and (max-height: 899px) {
      padding-top: 160px;
      margin-bottom: 50px;
    }

    @media screen and (max-height: 799px) {
      padding-top: 300px;
      margin-bottom: 60px;
    }

    @media screen and (max-height: 599px) {
      padding-top: 400px;
      margin-bottom: 60px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

/* ==========================================================================
Section right
========================================================================== */
.section--right {
  @extend .py-6, .py-md-0;

  background-color: $black;
  width: 100%;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
  }
}

.cases--sidebar {
  li {
    font-weight: 400;
  }
}

/* ==========================================================================
Section col inner
========================================================================== */
.col--inner {
  padding: 0 15px;

  h5 {
    @extend .mb-1;

    font-family: $font-family-1;

    &::before {
      content: '';
      display: block;
      position: relative;
      width: 25px;
      margin-bottom: 10px;
    }

    @include media-breakpoint-up(md) {
      &::before {
        display: none;
      }

      font-size: 28px;
    }
  }
}

.col--inner ul {
  padding: 0;

  li {
    list-style-type: none;
    line-height: 1.8;

    @include font-size(15);
  }

  @include media-breakpoint-up(md) {
    li {
      @include font-size(18);
    }
  }
}

/* ==========================================================================
Section half
========================================================================== */
.section--half {
  @extend .py-6, .py-md-0, .d-flex;

  width: 100%;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(md) {
    height: 100vh;
    width: 50%;
    padding: 0 15px;
  }

  .section--half-inner {
    @extend .col-lg-8, .px-3;
  }
}

.section--half-image {
  position: relative;
  height: auto;
  min-height: 600px;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: 100vh;
    width: 45%;
  }
}

.section--half-image-inner {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.vacancies {
  .section--half-image {
    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }
}

/* ==========================================================================
Section quarter
========================================================================== */
.section--quarter {
  @extend .py-10, .py-md-0, .d-flex;

  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    width: 45%;
    padding: 0 15px;
  }

  .section--quarter-inner {
    @extend .col-lg-8, .px-3;

    h6:nth-of-type(1) {
      @extend .mb-0;
    }

    h6:nth-of-type(2) {
      @extend .subtitle;
    }
  }
}

.section--quarter-image {
  position: relative;
  height: auto;
  min-height: 600px;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: 100vh;
    width: 54%;
  }
}

.section--quarter-image-inner {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* ==========================================================================
Section columns
========================================================================== */
.section--columns {
  @extend .d-flex;

  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    justify-content: space-between;
    align-items: center;
  }
}

/* ==========================================================================
Section dark/light
========================================================================== */
.section--color-dark {
  background: black;
  color: white;

  .section--right {
    background-color: $black;

    @include media-breakpoint-up(md) {
      background-color: $gray-custom-1;
    }
  }

  .col--inner-title {
    &::before {
      border-bottom: 2px solid $white;
    }
  }

  .section-btn {
    @extend .btn-outline-light;
  }

  .contact-btn {
    @extend .btn-white;
  }

  a {
    color: $white;
  }

  .tns-controls {
    button {
      border-color: $white;

      path {
        fill: $white;
      }
    }
  }

  .team--category-btn {
    color: $white;
    background-color: $black;

    &:hover,
    &:focus {
      color: $black;
      background-color: $white;
      border-radius: 0;
    }
  }

  .team--category-active {
    .team--category-btn {
      color: $black;
      background-color: $white;
      border-color: $white;
    }
  }

  .social--media-link {
    color: $white;

    &::after {
      background-color: $white;
    }
  }
}

.stories .contact--email-signup {
  @extend .py-2;
  @extend .px-4;
  background-color: #111111;

  label {
    @extend .mt-2;
  }

  @include media-breakpoint-down(md) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

// needs refactoring
.stories .contact--email-signup .contact--email-form {
  .logo {
    width: 100px;
    height: 100px;
  }

  h2 {
    text-align: center;
    text-transform: uppercase;
    @extend .mb-0;
  }

  @include media-breakpoint-down(xl) {
    h2 {
      font-size: 1.75rem;
    }

    .logo {
      width: 50px;
      height: 50px;
    }
  }

  @include media-breakpoint-down(sm) {
    .logo {
      width: 100px;
      height: 100px;
    }
  }

  @include media-breakpoint-down(lg) {
    label {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    form {
      padding-top: 10px;
    }

    h2 {
      font-size: 1.25rem;
    }

    .btn {
      font-size: 18px !important;
    }
  }

  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 3.75rem;
    }

    label {
      display: block;
    }

    .btn {
      font-size: 1.375rem !important;
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 2.75rem;
    }

    label {
      display: block;
    }

    .btn {
      font-size: 1.375rem !important;
    }
  }
}

.section--color-light {
  @extend .bg-white, .text-black;

  .section--right {
    background-color: $white;

    @include media-breakpoint-up(md) {
      background-color: $gray-custom-2;
    }
  }

  .col--inner-title {
    &::before {
      border-bottom: 2px solid $black;
    }
  }

  .section-btn {
    @extend .btn-outline-black;
  }

  .contact-btn {
    @extend .btn-black;
  }

  a {
    color: $black;
  }

  .tns-controls {
    button {
      border-color: $black;

      path {
        fill: $black;
      }
    }
  }

  .team--category-btn {
    @include media-breakpoint-up(md) {
      color: $black;
      background-color: $white;
      border-color: $black;

      &:hover,
      &:focus {
        color: $white;
        background-color: $black;
      }
    }
  }

  .team--category-active {
    .team--category-btn {
      &::after {
        background-color: $black;
      }

      @include media-breakpoint-up(md) {
        color: $white;
        background-color: $black;
        border-color: $black;

        &::after {
          display: none;
        }
      }
    }
  }

  .social--media-link {
    color: $black;

    &::after {
      background-color: $black;
    }
  }
}
