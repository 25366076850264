.vacancy-form {
  .form-field {
    border: 0;
    background-color: $gray-custom-3;
    display: block;
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
    padding-left: 10px;
    width: 100%;
    color: white;
    margin-bottom: 10px;

    &:focus {
      outline: 1px solid $white;
    }
  }

  .btn {
    @extend .mt-0;
  }

  .inline-link {
    text-decoration: underline;
  }

  .form-field-file {
    height: 35px;
    line-height: 35px;
    margin-bottom: 10px;

    &:focus {
      outline: none;
    }
  }

  .form-field--required::after {
    content: "*";
    color: $white;
  }

  .form-label {
    @extend .mb-0;

    font-size: 18px;
    font-weight: 300;
  }

  .form-group-terms {
    .form-label {
      @extend .pl-1;
    }
  }

  textarea[name='motivation'] {
    min-height: 120px;
    overflow: auto;

    &:focus {
      outline: 1px solid $white;
    }
  }
}
