/* ==========================================================================
Contact
========================================================================== */
.contact--small {
  @extend .mb-1;

  display: inline-block;
  font-weight: bold;

  @include font-size(15);
}

.contact--img {
  @extend .mb-2;

  max-width: 165px;
  height: 145px;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.contact--email-signup {
  label {
    @extend
      .mt-md-4,
      .mb-0;

    width: 100%;
    font-weight: bold;

    @include letter-spacing(50);
  }

  input[type='text'],
  input[type='email'] {
    border: 0;
    background-color: $gray-custom-3;
    color: $white;
    display: block;
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
    padding-left: 10px;
    width: 100%;

    &:focus {
      outline: 1px solid $white;
    }
  }

  .btn {
    @extend .mt-2;
  }

  .contact--email-signup {
    label {
      @extend .my-4;

      width: 100%;
      font-weight: bold;

      @include letter-spacing(50);
    }

    input[type='text'] {
      border: 2px solid white;
      display: block;
      height: 40px;
      line-height: 40px;
      margin-top: 5px;
      padding-left: 10px;
      width: 100%;
    }

    ::placeholder {
      font-weight: bold;
      color: $white;
    }

    .btn {
      @extend .mt-2;
    }
  }

  /* ==========================================================================
  Contact page
  ========================================================================== */
  .contact--page-content {
    p {
      @extend .mb-0;

      font-weight: 400 !important;

      @include letter-spacing(50);
    }

    .btn {
      @extend .my-2;
    }

    p:nth-child(5) {
      @extend .mt-4;
    }

    p:nth-child(7) a {
      @extend .btn, .btn-white, .btn-min-width, .d-inline-block;
      text-decoration: none;
    }

    p:nth-child(9) {
      a {
        color: rgba($white, 0.5);
      }
    }
  }

  .contact--page-content h1 {
    @extend .mb-1, .h2;
  }
}

::placeholder {
  color: $white;
}

/* ==========================================================================
Contact page
========================================================================== */
.contact--page-content {
  p {
    @extend .mb-0;

    font-weight: 400 !important;

    @include letter-spacing(50);
  }

  .btn {
    @extend .my-2;
  }
}

.contact--page-title {
  @extend .h2, .mb-2;
}

.contact--page-link {
  @extend .d-block;

  color: $white;
  font-weight: 400 !important;
  text-decoration: underline;

  @include font-size(18);
  @include letter-spacing(50);


}

.contact--page-link-last {
  color: rgba(white, 0.5) !important;
}

.contact--page-social {
  @extend .pl-0;
}

.contact--page-item {
  @extend .mr-2;

  list-style-type: none;
  display: inline-block;
}

.contact-form {
  @extend .mt-3;

  display: flex;
  flex-direction: column;

  p {
    font-weight: bold !important;

    @include letter-spacing(50);
  }

  .checkbox{
    border: 0;
    background-color: $gray-custom-3;
    display: block;
    height: 25px;
    width: 25px; 
    line-height: 40px;
    margin-top: 5px;
    padding-left: 10px;
    color: white;
    margin-bottom: 10px;
  }

  input,
  textarea {
    border: 0;
    background-color: $gray-custom-3;
    display: block;
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
    padding-left: 10px;
    width: 100%;
    color: white;
    margin-bottom: 10px;


    &:focus {
      outline: 1px solid $white;
    }
  }

  textarea[name='Vraag'] {
    min-height: 80px;
    resize: none;
    overflow: auto;

    &:focus {
      outline: 1px solid $white;
    }
  }
}
