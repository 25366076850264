/* ==========================================================================
Global
========================================================================== */
html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: $body-color;
  scroll-behavior: smooth;
}

body:not(.home) {
  .main {
    @extend .pt-lg-7;
  }
}

.container-fluid {
  @extend .px-0;
}

.box {
  @extend .mr-2;

  width: 80px;
  height: 50px;
}

.box--color {
  font-size: 11px;
}

.box--wrapper {
  display: inline-block;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

img.bg-image {
  font-family: 'object-fit: cover;';
  object-fit: cover;
}

ul {
  font-weight: 300;
  list-style-type: none;
  padding: 0;
}

.vacancy {
  .section--half, .section--full, .section--quarter, .section--auto {
    li {
      position: relative;
      left: 20px;
    }
  
    li::before {
      content: "";
      height: 10px;
      width: 10px;
      background: $white;
      position: absolute;
      top: 9px;
      left: -20px;
    }
  }
}

.ecommerce{

  .slide-grid {
    margin: 0rem 12rem 0rem 12rem;
    .slide-content{
      overflow: hidden;
    }
    @include media-breakpoint-down(xl) {
      margin: 0rem 7rem 0rem 7rem;
    }

    @include media-breakpoint-down(md) {
      margin: 0rem 5rem 0rem 5rem;
      .slide-content {
        h2 {
          transform: translateY(0px);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin: 0rem;
      .slide-grid_column{
        margin: 0 !important; 
      }
      .slide{
        margin-top: 3vh !important;
      }
    }
  }  
  .col-md-10{
    h1{
      text-align: center !important; //Override default block values 
      margin-top: 15vh; 
      margin-bottom: 15vh !important; //Override default block values 
    }
  }
  .col-xl-10{
    @extend .col-xl-8;
  }
}

.story {
  .section--half, .section--full, .section--quarter, .section--auto {
    li {
      position: relative;
      left: 20px;
    }

    li::before {
      content: "-";
      position: absolute;
      left: -20px;
    }
  }
}