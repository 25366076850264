/* ==========================================================================
Navbar
========================================================================== */
.navbar {
  width: 100%;
  padding: 0 0.625rem;

}

/* ==========================================================================
Navbar Brand
========================================================================== */
.navbar-brand {
  @extend
    .p-0,
    .m-0;

  position: relative;
  height: 53px;
  width: 125px;
  z-index: 531;

  @include media-breakpoint-up(md) {
    height: 63px;
    width: 150px;
  }

  img {
    width: 100%;
  }
}

.navbar-collapse {
  @extend .fixed-top;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  background-color: $black;
  right: 0 !important;
}

/* ==========================================================================
Nav Item
========================================================================== */
.nav-item-list {
  @extend
    .p-0,
    .mb-0,
    .mb-md-1;
    display: block;
    text-align: right;
}

.nav-item {
   list-style-type: none;
   &:nth-child(2),
   &:nth-child(3) {
     margin-right: 000;
     margin-left: 0;
   }
  .nav-link {
    color: $white;
    font-family: $font-family-2;
    font-weight: 800;
    line-height: 0.9;
    list-style-type: none;
    text-transform: uppercase;
    font-size: 5.5rem;

    @include transition();

    @include media-breakpoint-up(md) {
      @include font-size(130);
    }

    &:hover {
      padding-right: 40px;
    }
  }
}

.nav-item--small {
  @extend
    .ml-md-4,
    .ml-2,
    .d-inline-block;
  @include font-size(16);

  margin-left: 40px;

  .nav-link {
    color: $white;
    font-weight: 600;
    text-decoration: underline;
  }
}


/* ==========================================================================
Bottombar
========================================================================== */
.bottombar {
  width: 100%;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 15px;
  z-index: 12;

  @media only screen and (max-width: 320px) {
    bottom: 12px;
  }

  .container {
    display: flex;
    justify-content: space-between;

    .navbar-collapse {
      top: 90%;

      @include media-breakpoint-up(md) {
        top: 0;
      }
    }
  }
}

.bottombar--inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-block;
  left: 0;
  bottom: 0;
}

.bottombar--arrow-left,
.bottombar--arrow-right,
.bottombar--square {
  @include font-size(20);
  width: $bottombar-arrow-size;
  background-color: $black;
  border-radius: 0;
  border: 0;
  color: $white;
  height: $bottombar-arrow-size;
  line-height: $bottombar-arrow-size;
  text-align: center;

  .label--hidden{
    max-width: 0;
    opacity: 0;
    white-space: nowrap;
    transition: .2s;
    color: white;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;

    .label--hidden {
      max-width: 300px;
      opacity: 1;
    }

    .label--hidden-left {
      margin-left: 8px;
    }

    .label--hidden-right {
      margin-right: 8px;
    }
  }

  @include media-breakpoint-up(md) {
    width: unset;
    display: inline-flex;
    position: relative;
    align-items: center;
    overflow: hidden;
    transition: .2s;
  }
}

.bottombar--arrow-left {
  svg{
    transform: rotate(90deg);
  }
}

.bottombar--arrow-right,
.bottombar--arrow-left {
  svg {
    position: relative;
    top: -3px;
  }

  @include media-breakpoint-up(md) {
    padding: 0 18px 0 15px;

    svg {
      top: -1px;
    }
  }
}

.bottombar--arrow-right {
  svg{
    transform: rotate(270deg);
  }
}

.bottombar--square {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  @include media-breakpoint-up(md) {
    padding: 0 18px;

    svg {
      transition: transform .2s;
      position: relative;
      top: 0;
    }

    &:hover {
      svg {
        transform: rotate(360deg)
      }
    }
  }
}
