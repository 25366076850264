/* ==========================================================================
Hero
========================================================================== */
.hero {
  overflow: hidden;
  position: relative;
}

/* ==========================================================================
  Hero block
  ========================================================================== */
.hero--block {
  position: relative;
  overflow: hidden;

  &::before {
    @extend .w-100;

    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

/* ==========================================================================
  Hero Text
  ========================================================================== */
.hero--text {
  @extend .pb-10, .col-sm-10, .col-md-8, .col-xl-8, .px-3, .px-md-0;
}

.hero--text-mobile {
  @extend .col-8;

  padding-bottom: 0 !important;
}

/* ==========================================================================
  Hero content
  ========================================================================== */
.hero--content {
  h1,
  h2 {
    @extend .mb-1;
  }

  h2 {
    @extend .h1;
  }

  h4,
  h5 {
    @extend .mb-1;
    text-transform: uppercase;
  }

  p {
    line-height: 1.4;
  }
}

/* ==========================================================================
  Hero title
  ========================================================================== */
.hero--title {
  @extend .h1, .mb-1;
}

.hero-font--large {
  @include media-breakpoint-up(xxl) {
    @include font-size(200);
  }
}

/* ==========================================================================
  Hero subtitle
  ========================================================================== */
.hero--subtitle {
  @extend .h4, .mb-1;
}

/* ==========================================================================
  Hero image
  ========================================================================== */
.hero--image {
  @extend .h-100;
  @extend .w-100;

  //background-image: url("/assets/img/bg-slide2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  z-index: 1;

  picture {
    @extend .d-block;
    @extend .h-100;
  }

  img {
    @extend .w-100;

    object-fit: cover;
    object-position: 50% 50%;
  }
}

/* ==========================================================================
  Hero image overlay
  ========================================================================== */
.hero--image-overlay {
  @extend .d-none;
  @extend .d-lg-flex;
  @extend .flex-wrap;
  @extend .flex-row;

  position: absolute;
  bottom: 0;
  right: 10%;
  z-index: 2;

  .overlay--text {
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    @extend .px-2;

    width: 180px;
    height: 80px;
    background-color: $theme-primary;
    color: $black;

    p {
      @extend .mb-0;
    }
  }

  .overlay--logo {
    max-width: 80px;
    max-height: 80px;
  }
}

/* ==========================================================================
  Hero buttons
  ========================================================================== */
.hero--buttons {
  font-size: 0;

  @include media-breakpoint-only(xs) {
    .btn {
      width: 100%;
    }
  }

  .btn {
    @extend .mb-1;
    @extend .mb-sm-0;
    @extend .btn-min-width;
  }

  .btn + .btn {
    @extend .ml-sm-1;
  }
}

/* ==========================================================================
  Hero color (dark / light)
  ========================================================================== */
.hero--color-dark {
  background-color: $body-color;

  .hero--content {
    color: $white;
  }

  .btn {
    @extend .btn-white;
  }
}

.hero--color-light {
  background-color: $white;

  .hero--title,
  .hero--subtitle,
  p {
    color: $body-color;
  }

  .btn {
    @extend .btn-black;
  }
}

/* ==========================================================================
  Hero align (center / left / right)
  ========================================================================== */
.hero--align-center {
  .row {
    @extend .align-items-center;

    @include media-breakpoint-down(md) {
      h1 {
        font-size: 110px;
      }
    }
  }

  &.hero--color-dark {
    &::before {
      background: linear-gradient(
        to right,
        rgba($black, 1) 0%,
        rgba($black, 0) 100%
      );
    }
  }

  &.hero--color-light {
    &::before {
      background: linear-gradient(
        to right,
        rgba($white, 1) 0%,
        rgba($white, 0) 100%
      );
    }
  }
}

.hero--align-bottom {
  .row {
    @extend .align-items-end;
  }

  .row-mobile {
    @extend .align-items-center, .justify-content-center;

    .hero--text {
      @extend .px-0;
    }
  }

  &.hero--color-dark {
    &::before {
      background: linear-gradient(
        to top,
        rgba($black, 1) 5%,
        rgba($black, 0) 100%
      );
    }
  }

  &.hero--color-light {
    &::before {
      background: linear-gradient(
        to top,
        rgba($white, 1) 5%,
        rgba($white, 0) 100%
      );
    }
  }
}

/* ==========================================================================
  Hero arrow
  ========================================================================== */
.hero--arrow-left,
.hero--arrow-right,
.hero--square {
  @include font-size(20);

  background-color: $black;
  border-radius: 0;
  bottom: 20px;
  color: $white;
  height: $hero-arrow-size;
  line-height: $hero-arrow-size;
  position: absolute;
  text-align: center;
  width: $hero-arrow-size;
  z-index: 3;
}

.hero--arrow-left {
  left: 0;
  transform: rotate(90deg);
}

.hero--arrow-right {
  right: 0;
  transform: rotate(270deg);
}

.hero--square {
  left: 50%;
  transform: translateX(-50%);
}

/* ==========================================================================
  Hero height (xs / sm / md / lg / xl / xxl)
  ========================================================================== */
.hero--height-xs {
  .row {
    min-height: $hero--height-xs;
  }

  .hero--image {
    img {
      height: $hero--height-xs;
    }
  }
}

.hero--height-sm {
  .row {
    min-height: $hero--height-sm;
  }

  .hero--image {
    img {
      height: $hero--height-sm;
    }
  }
}

.hero--height-md {
  .row {
    min-height: $hero--height-md;
  }

  .hero--image {
    img {
      height: $hero--height-md;
    }
  }
}

.hero--height-lg {
  .row {
    min-height: $hero--height-lg;
  }

  .hero--image {
    img {
      height: $hero--height-lg;
    }
  }
}

.hero--height-xl {
  .row {
    min-height: $hero--height-xl;
  }

  .hero--image {
    img {
      height: $hero--height-xl;
    }
  }
}

.hero--height-xxl {
  .row {
    min-height: $hero--height-xxl;
  }

  .hero--image {
    img {
      height: $hero--height-xxl;
    }
  }
}

/* ==========================================================================
  Hero video
  ========================================================================== */
.hero--video {
  @extend .h-100;
  @extend .w-100;

  position: absolute;
  top: 0;
  z-index: 1;
}

.muted--icon,
.unmuted--icon {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 20px;
  text-align: center;
  color: $white;
  border-radius: 50%;
  background: rgba($black, 0.5);
  line-height: 2.4rem;
  position: absolute;
  right: 20px;
  bottom: 50px;
  z-index: 10;
  cursor: pointer;

  &:hover {
    background: $black;
  }
}

/* ==========================================================================
  Video
  ========================================================================== */
.hero--video-background {
  background: $black;
  bottom: 0;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease-in-out;
}

.hero--video-foreground,
.hero--video-background iframe {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.hero--video-foreground {
  height: 300%;
  top: -100%;
}

@media (max-aspect-ratio: 16/9) {
  .hero--video-foreground {
    left: -100%;
    width: 300%;
  }
}

/* ==========================================================================
  Plyr
  ========================================================================== */
.plyr {
  height: 100%;
}

.plyr__video-wrapper {
  @extend .hero--video-foreground;
}
