/* ==========================================================================
Colors
========================================================================== */
$white: #fff; // Default BS4
$gray-100: #f8f9fa; // Default BS4
$gray-200: #e9ecef; // Default BS4
$gray-300: #dee2e6; // Default BS4
$gray-400: #ced4da; // Default BS4
$gray-500: #adb5bd; // Default BS4
$gray-600: #6c757d; // Default BS4
$gray-700: #495057; // Default BS4
$gray-800: #343a40; // Default BS4
$gray-900: #212529; // Default BS4
$black: #000; // Default BS4

$gray-custom-1: #111111;
$gray-custom-2: #999999;
$gray-custom-3: #333333;

$blue: #007bff; // Default BS4
$indigo: #6610f2; // Default BS4
$purple: #6f42c1; // Default BS4
$pink: #e83e8c; // Default BS4
$red: #cc0c00; // Default BS4
$orange: #fd7e14; // Default BS4
$yellow: #ffc107; // Default BS4
$green: #28a745; // Default BS4
$teal: #20c997; // Default BS4
$cyan: #17a2b8; // Default BS4

$theme-primary: #f4bc00; // Custom Theme Color
$theme-secondary: #00aac2; // Custom Theme Color
$theme-tertiary: #e2836e; // Custom Theme Color

$danger: $red; // Default BS4
$dark: $gray-800; // Default BS4
$info: $cyan; // Default BS4
$light: $gray-100; // Default BS4
$primary: $blue; // Default BS4
$secondary: $gray-600; // Default BS4
$success: $green; // Default BS4
$warning: $yellow; // Default BS4

$theme-colors: ();
$theme-colors: map-merge(
  (
    'black': $black,
    'danger': $danger,
    'dark': $dark,
    'info': $info,
    'light': $light,
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'warning': $warning,
    'white': $white,
    'theme-primary': $theme-primary,
    'theme-secondary': $theme-secondary,
    'theme-tertiary': $theme-tertiary,
    'gray-custom-1': $gray-custom-1,
    'red': $red,
  ),
  $theme-colors
);

// https://getbootstrap.com/docs/4.0/getting-started/theming/
$yiq-text-dark: $black;

/* ==========================================================================
Body
========================================================================== */
// $body-bg:                               $theme-gray-100;
$body-color: $black;

/* ==========================================================================
Box shadow;
========================================================================== */
$box-shadow-1: 0 1px 1px 0 rgba($black, 0.25);
$box-shadow-2: 0 3px 6px 0 rgba($black, 0.15);

/* ==========================================================================
Buttons
========================================================================== */
$btn-border-radius: 0;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-width: 2px;

// Default
$btn-font-size: 1.375rem; // 22px
$btn-font-weight: 700;
$btn-line-height: 1.25rem; // 20px
$btn-padding-x: 1.375rem; // 20px
$btn-padding-y: 0.5rem; // 8px

// Small
$btn-font-size-sm: 0.8125rem; // 13px
$btn-line-height-sm: $btn-line-height;
$btn-padding-x-sm: 0.9375rem; // 15px
$btn-padding-y-sm: 0.1875rem; // 3px

// Large
$btn-font-size-lg: 1.063rem; // 17px
$btn-line-height-lg: $btn-line-height;
$btn-padding-x-lg: 1.563rem; // 25px
$btn-padding-y-lg: 0.8125rem; // 13px

/* ==========================================================================
Cards
========================================================================== */
// $card-border-radius:                    0;

/* ==========================================================================
Concrete5
========================================================================== */
$ccm-toolbar-height: 48px;

/* ==========================================================================
Dropdowns
========================================================================== */
$dropdown-border-color: $gray-400;
$dropdown-link-active-bg: $theme-primary;

/* ==========================================================================
Forms
========================================================================== */
$input-bg: rgba($black, 0.05);
$input-border-color: $black;
$input-border-radius: 0;
$input-border-radius-lg: $input-border-radius;
$input-border-radius-sm: $input-border-radius;
$input-color: $black;

/* ==========================================================================
Fonts
========================================================================== */
$font-family-1: 'Lato', sans-serif;
$font-family-2: 'Druk Web', sans-serif;
$font-family-base: $font-family-1;

/* ==========================================================================
Footer
========================================================================== */
$footer-height: 60px;

/* ==========================================================================
Grid breakpoints
========================================================================== */
$grid-breakpoints: ();
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1430px,
  ),
  $grid-breakpoints
);

/* ==========================================================================
Grid containers
========================================================================== */
$container-max-widths: ();
$container-max-widths: map-merge(
  (
    xs: 480px,
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1430px,
  ),
  $container-max-widths
);

/* ==========================================================================
Hamburgers (https://github.com/jonsuh/hamburgers)
========================================================================== */
$hamburger-active-hover-opacity: 1;
$hamburger-active-layer-color: $black;
$hamburger-hover-opacity: 1;
$hamburger-layer-border-radius: 0;
$hamburger-layer-color: $black;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 3px;
$hamburger-layer-width: 30px;
$hamburger-padding-x: 10px;
$hamburger-padding-y: 0;
$hamburger-position: 20px;
$hamburger-types: (boring);

/* ==========================================================================
Header
========================================================================== */
$navbar-height: 70px;

/* ==========================================================================
Bottom
========================================================================== */
$bottombar-arrow-size: 53px;
$bottombar--height-xs: 200px;
$bottombar--height-sm: 300px;
$bottombar--height-md: 400px;
$bottombar--height-lg: 500px;
$bottombar--height-xl: 600px;
$bottombar--height-xxl: 100vh;

/* ==========================================================================
Hero
========================================================================== */
$hero-arrow-size: 50px;
$hero--height-xs: 200px;
$hero--height-sm: 300px;
$hero--height-md: 400px;
$hero--height-lg: 500px;
$hero--height-xl: 600px;
$hero--height-xxl: 100vh;

/* ==========================================================================
Modals
========================================================================== */
$modal-backdrop-bg: $theme-secondary;
$modal-backdrop-opacity: 1;
$modal-content-bg: $theme-secondary;
$modal-content-border-color: $black;
$modal-content-border-width: 1px;
$modal-content-border-radius: 0;
$modal-content-color: $white;
$modal-xl: 1350px;
$modal-lg: 100%;
$modal-md: 100%;
$modal-sm: 100%;

// Modal animations
$modal-fade-transform: 0;

/* ==========================================================================
Pagination
========================================================================== */
$pagination-border-color: $black;
$pagination-border-width: 2px;
$pagination-color: $black;

// Active
$pagination-active-color: $white;
$pagination-active-bg: $black;
$pagination-active-border-color: $black;

// Hover
$pagination-hover-color: $white;
$pagination-hover-bg: $black;
$pagination-hover-border-color: $black;

/* ==========================================================================
Spacing
========================================================================== */
$spacer: 0.625rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: $spacer,
    // 10px
    15:
      (
        $spacer * 1.5,
      ),
    // 15px
    2:
      (
        $spacer * 2,
      ),
    // 20px
    25:
      (
        $spacer * 2.5,
      ),
    // 25px
    3:
      (
        $spacer * 3,
      ),
    // 30px
    35:
      (
        $spacer * 3.5,
      ),
    // 35px
    4:
      (
        $spacer * 4,
      ),
    // 40px
    45:
      (
        $spacer * 4.5,
      ),
    // 45px
    5:
      (
        $spacer * 5,
      ),
    // 50px
    55:
      (
        $spacer * 5.5,
      ),
    // 55px
    6:
      (
        $spacer * 6,
      ),
    // 60px
    65:
      (
        $spacer * 6.5,
      ),
    // 65px
    7:
      (
        $spacer * 7,
      ),
    // 70px
    75:
      (
        $spacer * 7.5,
      ),
    // 75px
    8:
      (
        $spacer * 8,
      ),
    // 80px
    85:
      (
        $spacer * 8.5,
      ),
    // 85px
    9:
      (
        $spacer * 9,
      ),
    // 90px
    95:
      (
        $spacer * 9.5,
      ),
    // 95px
    10:
      (
        $spacer * 10,
      ),
  ),
  $spacers
);

/* ==========================================================================
Z-index
========================================================================== */
$zindex-dropdown: 500;
$zindex-sticky: 520;
$zindex-fixed: 530;
$zindex-modal-backdrop: 540;
$zindex-modal: 550;
$zindex-popover: 560;
$zindex-tooltip: 570;
