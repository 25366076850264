.loader {
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    background-color: #000;
  }
  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    pointer-events: none;
    opacity: 1;
  }
  &__complete {
    background-color: #FFF;
    position: absolute;
    -webkit-transition: opacity 0.2s ease-in-out 1.4s;
   -moz-transition: opacity 0.2s ease-in-out 1.4s;
   -ms-transition: opacity 0.2s ease-in-out 1.4s;
   -o-transition: opacity 0.2s ease-in-out 1.4s;
    opacity: 0;
  }
}
