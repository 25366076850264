.slide {
    @extend .overflow-hidden;

    .slide-image--kenburns {
      @include transition();
      transform: scale(1.0);
    }

    position: relative;
    background: transparent;
    color: $white;
    width: 100%;
    z-index: 10;

    &:hover,
    &:focus {
      .slide-background--gradient-bottom::after {
        opacity: 1;
      }
    }

    &-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      transition: all 400ms ease;
    }

    &-image {
      width: 100%;
      height: 100%;
      background: no-repeat center/cover;
      position: absolute;
      transition: all 400ms ease;
    }

    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-position: center;
      background-size: cover;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      transition: all 400ms ease;

      &--gradient-bottom::after {
        content: '';
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 30%);
        z-index: 3;

        @include transition();
      }
    }
  }

  .slide-content {
    @extend .w-100;

    display: flex;
    align-items: flex-end;
    backface-visibility: hidden;
    height: auto;
    left: 0;
    opacity: 1;
    position: relative;
    top: 0;
    z-index: 100;
    height: 100vh;
    overflow-y: scroll;
  }

  .slide-content--menu {
    background-color: black;
  }

  .slide-heading {
    @extend .mb-2;
  }

  .slide-category {
    font-weight: bold;
    @include font-size(15);
  }

  .slide-container {
    @extend
      .w-100,
      .p-md-4;

    display: table;
    height: 100vh;
    position: relative;
    table-layout: fixed;
  }

  .slide-container--vh-85 {
    height: 85vh;
  }

  .slide-container--vh-60 {
    height: 60vh;
  }

  .slide-wrap {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
  }

  .slide-wrap--bottom {
    vertical-align: bottom;
  }

  .vh-85 {
    height: auto;
    min-height: 85vh;
    height: 85vh;
  }

  .vh-100 {
    height: auto;
    min-height: 100vh;
    height: 100vh !important;
  }

  .vh-60 {
    height: auto;
    min-height: 60vh;
    height: 60vh;
  }
