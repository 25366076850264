.slide-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: 0px; /* gutter size offset */
  width: auto;
  background-color: black;

  &_column {
    padding-left: 0px; /* gutter size */
    background-clip: padding-box;

    & > div { /* change div to reference your elements you put in <Masonry> */
      background: grey;
      margin-bottom: 0px;
    }
  }

  a {
    text-decoration: none;
  }

  .slide-container {
    padding: 20px;
  }

  .slide-content h2 {
    font-size: 55px;
    text-transform: uppercase;
  }

  @include media-breakpoint-up(md) {
    .slide-content {
      h2 {
        @include transition(transform .2s cubic-bezier(.3,0,.7,1));
        transform: translateY(110px);
        font-size: 65px;
      }

      .slide-content-expand {
        @include transition(all .2s .0s cubic-bezier(.3,0,.7,1));
        transform: translateY(62px);
        opacity: 0;
      }

      &:hover {
        h2 {
          transform: translateY(0px);
        }

        .slide-content-expand {
          transform: translateY(0px);
          opacity: 1;
        }
      }

      &::after {
        opacity: 0;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, transparent 0%, black 100%);
      }

      .slide-content:hover::after {
        opacity: 1;
      }
    }

    .slide:hover {
      .slide-image--kenburns {
        @include transition();

        transform: scale(1.1);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .slide-content {
      h2 {
        transform: translateY(90px);
      }
    }
  }
}


@keyframes kenburns {
  100% {
    transform: scale(1.1);
    animation-timing-function: ease-in-out infinite;
    opacity: 1;
  }
}

.slide-title {
  line-height: 1;
}

.slide-text {
  @extend .mt-1;

  letter-spacing: 0.5px;
}
