/* ==========================================================================
Project slider variables
========================================================================== */
$project-slider-height: 312px;
$project-slider-image-height: 290px;

/* ==========================================================================
Project slider
========================================================================== */
.project--slider-wrapper {
  @extend .w-100;
  @extend .d-inline-block;

  height: $project-slider-height;
  position: relative;
  overflow: hidden;

  .tns-controls {
    @extend
    .w-100,
    .px-2,
    .px-md-6;

    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
  }
}

.project--slider {
  height: $project-slider-height;

  @include media-breakpoint-up(md) {
    height: 100vh !important;
  }
}

.project--slider-item {
  height: 100%;
  display: inline-block;
  background-image: url("/assets/img/bg-slide.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .lazyload-wrapper {
    height: 100%;
  }
}
